import React from 'react'
import { MultiLevel, SingleLevel } from '.'
import { PaymentSelection } from '../../../provider'
import { hasChildren } from './utils'

interface MenuItemProps {
  item: any
  countrySelection?: string
  paymentSelection?: PaymentSelection['value']
}

export const MenuItem: React.FC<MenuItemProps> = ({
  item,
  countrySelection,
  paymentSelection,
}) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel
  return (
    <Component
      {...item}
      paymentSelection={paymentSelection}
      countrySelection={countrySelection}
    />
  )
}

MenuItem.displayName = 'MenuItem'
