import React from 'react'
import {
  Mgm,
  Home,
  Desktop,
  SwitchCard,
  Phone,
  Website,
  Merchant,
  DirectDeposit,
  Flag,
  BillPayment,
  CheckCapture,
  Video,
} from '../../../icons'

const iconProps = {
  width: '21',
  height: '21',
  primaryColor: '#000000',
  secondaryColor: '#000000',
  thirdColor: '#000000',
}

export const navigation = [
  {
    href: '/',
    title: 'Home',
    icon: <Home {...iconProps} />,
  },
  {
    href: '/docs/online/',
    title: 'Online',
    icon: <Desktop {...iconProps} />,
  },
  {
    href: '/docs/in-person/',
    title: 'In-Person',
    icon: <SwitchCard {...iconProps} />,
  },
  {
    href: '/docs/multiparty/',
    title: 'Multiparty',
    icon: <Website {...iconProps} />,
  },
  {
    href: '/docs/3rd-party/',
    title: '3rd-Party',
    icon: <Merchant {...iconProps} />,
  },
  {
    href: '/docs/payouts/',
    title: 'Payouts',
    icon: <DirectDeposit {...iconProps} />,
  },
  {
    href: '/docs/disputes/',
    title: 'Disputes',
    icon: <Flag {...iconProps} />,
  },
  {
    href: '/docs/reports/',
    title: 'Reporting',
    icon: <BillPayment {...iconProps} />,
  },
  {
    href: '/docs/log-in-with-paypal/',
    title: 'Identity',
    icon: <CheckCapture {...iconProps} />,
  },
  {
    href: '/docs/archive/',
    title: 'Docs Archive',
    icon: <Mgm {...iconProps} />,
  },
  {
    href: '/video/home',
    title: 'Video',
    icon: <Video {...iconProps} />,
  },
]
