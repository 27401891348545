// @ts-nocheck
import React, { ReactNode, useState } from 'react'
import { isEmpty } from 'lodash'
import cls from 'classnames'
import {
  ISidebarIconWrapperLinkProps,
  ISidebarIconProps,
  SidebarIcon,
  SidebarIconTitle,
  SidebarIconWrapperLink,
  SidebarMenu,
} from '.'

import { useApplicationContext } from '../../../provider'

import './styles/sidebar-icons.css'
import './styles/sidebar.css'

interface SidebarProps {
  children: ReactNode | ReactNode[]
  isLimitedRelease: Boolean | any
  className?: string
  isFullWidth?: boolean
}

interface ISidebarComposition {
  SidebarIcon: React.FC<ISidebarIconProps>
  SidebarIconTitle: React.FC
  SidebarIconWrapperLink: React.FC<ISidebarIconWrapperLinkProps>
  SidebarMenu: React.FC
}

export const SidebarContext = React.createContext<SidebarProps | undefined>(
  undefined,
)

const Sidebar: React.FC<SidebarProps> & ISidebarComposition = ({
  children,
  isLimitedRelease = false,
  className,
  isFullWidth,
}: any) => {
  const [sidebarState] = useState()
  const { user } = useApplicationContext() //add elmo data
  const userLoggedOut = !user?.loggedIn

  const sidebarNavClassNames = cls(
    'main-menu',
    { ['main-menu main-menu-authed']: !userLoggedOut },
    className,
  )

  const sidebarContainerClassNames = cls('sidebar-container', {
    ['sidebar-container-fullwidth']: isFullWidth,
  })

  const sidebarMenuListContainerClasses = cls({
    ['limitedNav']: isLimitedRelease,
    ['sidebar-menu-list-container']: isFullWidth,
  })

  const sidebarInnerNavClassNames = cls('sidebar-nav', {
    ['sidebar-nav sidebar-nav_authed']: !userLoggedOut,
    ['sidebar-nav-fullwidth']: isFullWidth,
  })

  return (
    <SidebarContext.Provider value={sidebarState}>
      <div className="sidebar-slug-wrap" />
      <nav className={sidebarNavClassNames}>
        <ul>{children[0]}</ul>
      </nav>
      <div className={sidebarContainerClassNames}>
        <div className={sidebarInnerNavClassNames}>
          <ul className={`${sidebarMenuListContainerClasses} sideBar-ul`}>
            {children[1]}
          </ul>
        </div>
      </div>
    </SidebarContext.Provider>
  )
}

Sidebar.SidebarIcon = SidebarIcon
Sidebar.SidebarIconTitle = SidebarIconTitle
Sidebar.SidebarIconWrapperLink = SidebarIconWrapperLink
Sidebar.SidebarMenu = SidebarMenu

export { Sidebar }
