import React, { useState, createRef, useEffect } from 'react'
import styles from './ProfileDropdown.module.css'
import { DropdownListItemLink } from '../DropdownListItemLink'
import { traverseListWithKeyboard } from '../utils/keyboardNavigation'

interface ProfileDropdownProps {
  user: any
  logoutURL: any
  hardLogoutURL: string
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  user,
  logoutURL,
  hardLogoutURL,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [cursor, setCursor] = useState<any>(null)

  let itemList = [
    {
      title: 'Dashboard',
      classNames: styles.dropdownLinkDashboard,
      dataEnableSpinner: '/dashboard/',
      url: '/dashboard/',
      dataJS: null,
      dataBypass: null,
    },
    {
      title: 'My Account',
      classNames: styles.dropdownLinkMyAccount,
      dataEnableSpinner: '/developer/accountStatus/',
      url: '/developer/accountStatus/',
      dataJS: null,
      dataBypass: null,
    },
    {
      title: 'Logout',
      classNames: 'dx-auth-logout',
      dataEnableSpinner: null,
      url: logoutURL,
      dataJS: 'auth-btn',
      dataBypass: 'true',
    },
  ]
  if (hardLogoutURL) {
    itemList.unshift({
      title: 'Switch Account',
      classNames: styles.dropdownLinkDashboard,
      dataEnableSpinner: '/dashboard/',
      url: hardLogoutURL,
      dataJS: null,
      dataBypass: null,
    })
  }
  return (
    <div
      className={`${isOpen && styles.profileDisplayFocused} ${
        styles.profileDisplay
      } profileWrapper`}
      tabIndex={0}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
      onKeyDown={(e) => traverseListWithKeyboard(e, itemList, setCursor)}
    >
      <div
        className={`dx-bg-grad-radial-blue ${isOpen && styles.openProfileSVG}`}
      />
      <div className="profile__name inlineBlock">{user.firstName}</div>

      <div
        className={`${styles.dropdown} ${
          isOpen ? styles.openDropdown : styles.closedDropdown
        }`}
      >
        <ul className={styles.profileDropdownList}>
          {itemList.map((item, i) => (
            <li className={styles.listItem} key={i}>
              <DropdownListItemLink
                item={item}
                active={i === cursor}
                parent="profileDropdown"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ProfileDropdown
