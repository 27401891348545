import React from 'react'
import { Link } from '@paypalcorp/pp-react'
// import data from "../../content/footer.en-XC.yaml"
import footerStyles from './Footer.module.css'
import { SUPPORT_URL } from '../../integration-builder/utils/constants'

interface FooterProps {
  inverseHeaderScheme: boolean
  hide: boolean
}

const Footer: React.FC<FooterProps> = ({ inverseHeaderScheme, hide }) => {
  const footerLinks = [
    { text: 'Reference', link: '/reference', target: '_self' },
    { text: 'PayPal.com', link: 'https://www.paypal.com/', target: '_blank' },
    {
      text: 'Privacy',
      link: 'https://www.paypal.com/myaccount/privacy/privacyhub',
      target: '_blank',
    },
    {
      text: 'Cookies',
      link: 'https://www.paypal.com/myaccount/privacy/cookiePrefs',
      target: '_blank',
    },
    { text: 'Support', link: SUPPORT_URL, target: '_self' },
    {
      text: 'Legal',
      link: 'https://www.paypal.com/us/webapps/mpp/ua/legalhub-full',
      target: '_blank',
    },
    {
      text: 'Contact',
      link: 'https://www.paypal.com/us/smarthelp/contact-us',
      target: '_blank',
    },
  ]
  const links = footerLinks.map((l, i) => (
    <a
      key={`${l.text}-${i}`}
      href={l.link}
      className={`${footerStyles.footerLinks} ${footerStyles.footerText}`}
      target={l.target}
    >
      {l.text}
    </a>
  ))

  return (
    <div
      style={{ display: hide ? 'none' : 'inherit' }}
      className={
        inverseHeaderScheme
          ? footerStyles.footerContainerWhite
          : footerStyles.footerContainer
      }
    >
      <div className={footerStyles.footerWrapper}>
        <div className={footerStyles.linkBtnContainer}>{links}</div>
      </div>
    </div>
  )
}

export default Footer
