export const traverseListWithKeyboard = (e: any, list: any, setCursor: any) => {
  const listLength = list.children?.length || list.length

  const cursorStatement = (prevState: any) => {
    if (prevState === null) {
      return 0
    } else if (prevState < listLength - 1) {
      return prevState + 1
    }

    return prevState
  }

  if (e.key === 'ArrowDown') {
    e.preventDefault()
    setCursor((prevState: any) => cursorStatement(prevState))
  }
  if (e.key === 'ArrowUp') {
    setCursor((prevState: any) => (prevState > 0 ? prevState - 1 : prevState))
  }
}
