import cls from 'classnames'
import { withPrefix } from 'gatsby'
import { find, get } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import apiNavs from '../../content/navigation/api.yaml'
import limitedReleaseNavs from '../../content/navigation/limited-release.yaml'
import { MenuItem } from '../MenuItem/MenuItem'
import '../MenuItem/SingleLevel/single-level.css'
import { Sidebar } from '../Sidebar'
import { LimitedReleaseNav } from './limited-release-nav'
import { apiNavigation } from './mocks/apiNavigation'
import {
  getElement,
  getGrandParent,
  prependAppendSlashes,
  removeVersion,
  renderNav,
  trimSlashes,
} from './utils'

const API_REFRENCE = 'API Reference'
interface LeftJsonNavigationProps {
  location: Location
  leftNav: any
  showLeftNav: any
  hasIcons?: boolean
  className?: string
  isFullWidth?: boolean
}

const LeftJsonNavigation: React.FC<LeftJsonNavigationProps> = ({
  location,
  leftNav: data,
  showLeftNav,
  hasIcons = true,
  className,
  isFullWidth,
}) => {
  const [state, setState] = useState<any>({})
  const [currentDocs, setCurrentDocs] = useState([])
  const [isLimitedRelease, setIsLimitedRelease] = useState(false)

  // TODO: Create a story to clean up this file, because we will have different navigation.

  //const pathname = getPathnameWithPrefix(location.pathname)
  // reruns every time location pathname or location hash changes
  useEffect(() => {
    // Ignoring prefix (if any) as we need to highlight(by comparing the pathname with href) the selected left nav item, whose href does not include prefix.
    const pathname =
      withPrefix('/') !== '/'
        ? '/' + location.pathname.split('/').slice(2).join('/')
        : location.pathname
    // making sure that the pathname starts and ends with '/'
    setState({ pathname: prependAppendSlashes(pathname), hash: location.hash })
    setTimeout(function () {
      const activeHashElement = getElement('li.tip-nav-active-hash')
      const element = activeHashElement
        ? activeHashElement
        : getElement('li.api-active')
      if (element) element.scrollIntoView()
    }, 100)
  }, [location.pathname, location.hash])

  useEffect(() => {
    const element = document
      ? document.querySelector('li.tip-nav-active')
      : null
    let parentLi = getGrandParent(element)
    while (parentLi && parentLi.tagName === 'LI') {
      parentLi.classList = parentLi.classList + ' tip-nav-active-parent'
      parentLi = getGrandParent(parentLi)
    }
  }, [state.pathname])

  const { pathname = '', hash = '' } = state

  const apiReference = ({
    page: { grouped_routes, title, apihref, definitions, hasError },
    index,
  }: any) => {
    return (
      <>
        <LimitedReleaseNav
          index={index}
          page={{
            routes: grouped_routes,
            title,
            apihref,
            definitions,
            hasError,
          }}
          pathname={pathname}
          hash={hash}
          isFullWidth={isFullWidth}
          showLeftNav={showLeftNav}
        />
      </>
    )
  }

  const renderList = React.useMemo(() => {
    let renderList = null
    if (pathname.includes('limited-release')) {
      if (pathname.includes('/api/')) {
        const matchedData =
          find(data, (d) => trimSlashes(d.apihref) === trimSlashes(pathname)) ||
          {}
        const x_publicDocsPath =
          'api/limited-release/' + matchedData.namingOverrides.x_publicDocsPath
        const backNavigation = {
          title: matchedData.title,
          url: prependAppendSlashes(x_publicDocsPath),
        }
        const apiRefNav = {
          title: API_REFRENCE,
          url: prependAppendSlashes(matchedData.apihref),
          childNode: apiReference({ page: matchedData, index: 0 }),
        }
        const dynamicNav = [backNavigation, apiRefNav]
        renderList = renderNav({ array: dynamicNav, pathname })
      } else {
        const matchedNav = get(
          find(limitedReleaseNavs, (nav) => pathname.includes(nav.url)),
          'children',
          [],
        )
        renderList = renderNav({ array: matchedNav, pathname })
      }
    } else {
      const allNavs = apiNavs.map((nav: any) => {
        const parentNav: any = {
          title: nav.title,
          url: nav.url,
        }
        parentNav.childNode = get(nav, 'children', []).map(
          (cNav: any, index: string | number) => {
            if (cNav.url) {
              let apihref = trimSlashes(cNav.url)
              if (
                /\/v\d+/.test(pathname) &&
                trimSlashes(removeVersion(apihref)) ===
                  trimSlashes(removeVersion(pathname))
              ) {
                const url = removeVersion(apihref)
                const version = pathname.match(/\/v\d+/)[0]
                apihref = `${url}${version}`
              }
              const pageData = find(data, { apihref })
              if (pageData)
                return apiReference({
                  page: {
                    ...pageData,
                    title: get(
                      pageData,
                      'namingOverrides.x_navTitle',
                      pageData.title,
                    ),
                  },
                  index,
                })
            }
            return renderNav({ array: [cNav], pathname })
          },
        )
        return parentNav
      })
      renderList = renderNav({ array: allNavs, pathname })
    }
    return renderList
  }, [pathname, apiReference])

  useEffect(() => {
    for (let i = 0; i < limitedReleaseNavs.length; i++) {
      const element = limitedReleaseNavs[i]
      if (location.pathname.includes(element.url)) {
        setCurrentDocs(element)
      }
    }

    return () => setCurrentDocs([])
  }, [location.pathname])

  const currentNav = useMemo(() => {
    if (location.pathname.includes('limited-release')) {
      setIsLimitedRelease(true)
      return [currentDocs]
    }

    return apiNavs
  }, [currentDocs, location.pathname])

  return (
    <>
      <Sidebar
        isFullWidth={isFullWidth}
        className={cls({ ['sidebar-hidden-icons']: !hasIcons }, className)}
        isLimitedRelease={isLimitedRelease}
      >
        {hasIcons &&
          apiNavigation &&
          apiNavigation.map(({ title = '', href = '', icon }) => (
            <Sidebar.SidebarIconWrapperLink
              href={href}
              key={href}
              title={title}
            >
              <Sidebar.SidebarIcon>{icon}</Sidebar.SidebarIcon>
              <Sidebar.SidebarIconTitle>{title}</Sidebar.SidebarIconTitle>
            </Sidebar.SidebarIconWrapperLink>
          ))}
        {location.pathname.includes('api/limited-release') ? (
          renderList
        ) : (
          <Sidebar.SidebarMenu>
            {currentNav &&
              currentNav.map(({ title = '', url = '', children = [] }: any) => (
                <>
                  <h5 className="sidebar-title docsHeader-title" key={url}>
                    {title}
                  </h5>
                  {children &&
                    children.map((child: any, index: any) => (
                      <MenuItem key={index} item={child} />
                    ))}
                </>
              ))}
          </Sidebar.SidebarMenu>
        )}
      </Sidebar>
    </>
  )
}

LeftJsonNavigation.displayName = 'LeftJsonNavigation'

export default LeftJsonNavigation
