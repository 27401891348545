import React from 'react'
import { MenuItem } from '../..'
import './multilevel-child.css'

interface MultiLevelChildProps {
  data: any
}

export const MultiLevelChild: React.FC<MultiLevelChildProps> = ({ data }) => {
  return (
    <div className="multilevel-child-container">
      {data.map((child: any, key: React.Key | null | undefined) => (
        <a
          key={key}
          href={child.url}
          id={child.url}
          style={{ marginLeft: '8px' }}
          className={
            window.location.pathname === child.url
              ? 'multilevel-child-open multilevel-child'
              : 'multilevel-child'
          }
        >
          <MenuItem item={child} />
        </a>
      ))}
    </div>
  )
}
