import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { useWindowSize } from '../../../hooks/useWindowSize/useWindowSize'
import { MobileNavbar } from './MobileNavbar/MobileNavbar'
import { Navbar } from './Navbar/Navbar'
import ProfileDropdown from './ProfileDropdown'
import DashboardDropdown from './DashboardDropdown'
import './newHeader.css'

interface NewHeaderProps {
  user: any // TODO: define a new interface with all the values expected from the user and add use interface like UserProps
  location: Location
  inverseHeaderScheme: boolean
  leftNav: any
}

const NewHeader: React.FC<NewHeaderProps> = ({
  leftNav,
  user,
  location,
  inverseHeaderScheme,
}) => {
  const windowSize = useWindowSize()
  const [logoutURL, setLogoutURL] = useState('')
  const [hardLogoutURL, setHardLogoutURL] = useState('')
  const userLoggedOut = !user?.loggedIn

  useEffect(() => {
    if (!isEmpty(user)) {
      const currentUrl = (location && location.href) || '#'
      setLogoutURL(
        user.logout.url.replace(/PAGEURL/g, encodeURIComponent(currentUrl)),
      )
      if (user.webLLS) {
        setHardLogoutURL(
          user.hardLogout.url.replace(
            /PAGEURL/g,
            encodeURIComponent(currentUrl),
          ),
        )
      }
    }
  }, [location, user])

  return (
    <>
      <header
        className={`hp-PPCP-logged ${
          !userLoggedOut ? 'hp-PPCP-loggedNew' : ''
        }`}
      >
        {!userLoggedOut ? (
          <div className="hp-PPCP-loggedmainNav hp-PPCP-logged">
            <div data-testid="header_sub" className="header_sub">
              <div
                data-testid="header_sub_accountName"
                className="header_sub_accountName"
              />
              <div className="hp-PPCP-profileRightNav">
                <div className="header_sub_profile">
                  <div className="inlineBlock">
                    <DashboardDropdown />
                  </div>
                  <div className="inlineBlock">
                    <a
                      data-testid="header_sub_item_help"
                      className="header_sub_item_help help_link dx-no-eel"
                      href="https://www.paypal.com/smarthelp/home"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Help
                    </a>
                  </div>
                  <div className="inlineBlock">
                    <ProfileDropdown
                      user={user}
                      logoutURL={logoutURL}
                      hardLogoutURL={hardLogoutURL}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {/* <Container fluid={true} className={profileNav}>
                <ProfileComponent user = {user} logoutURL = {logoutURL}/>
                <Row>
                    <Col>
                        {user.loggedIn ? <ProfileComponent user = {user} logoutURL = {logoutURL}/> : null}
                    </Col>
                </Row>
            </Container> */}
        {windowSize.width! < 1128 ? (
          <MobileNavbar user={user} location={location} leftNav={leftNav} />
        ) : (
          <Navbar
            user={user}
            location={location}
            inverseHeaderScheme={inverseHeaderScheme}
          />
        )}
      </header>
    </>
  )
}

NewHeader.displayName = 'NewHeader'

export default NewHeader
