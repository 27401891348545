import { withPrefix } from 'gatsby'
import React, { useLayoutEffect, useMemo } from 'react'
import docsLeftNav from '../../content/navigation/nav.yaml'
import { MenuItem } from '../MenuItem'
import { useApplicationContext } from '../../../provider'
import { Sidebar } from '../Sidebar'
import { navigation } from './mocks/docsNavigation'
import { apiNavigation } from './mocks/apiNavigation'
import {
  findLeftNav,
  getGrandParent,
  urlMatch,
  modifyMultipartyPagesUrlsWithExperiment,
} from './utils'
import cls from 'classnames'

interface LeftMdNavigationProps {
  location: Location
  hasIcons?: boolean
  className?: string
  isFullWidth?: boolean
}

const LeftMdNavigation: React.FC<LeftMdNavigationProps> = ({
  location,
  hasIcons = true,
  className,
  isFullWidth,
}) => {
  const iconNav =
    location.pathname.includes('/api/') ||
    location.pathname.includes('/sdk/js/')
      ? apiNavigation
      : navigation
  const { countrySelection, paymentSelection, experiments } =
    useApplicationContext()
  const pathname =
    withPrefix('/') !== '/'
      ? '/' + location.pathname.split('/').slice(2).join('/')
      : location.pathname

  useLayoutEffect(() => {
    const element = document
      ? document.querySelector('li.sidebar-nav-active')
      : null
    let parentLi = getGrandParent(element)
    while (parentLi && parentLi.tagName === 'LI') {
      parentLi.classList = parentLi.classList + ' sidebar-nav-active-parent'
      parentLi = getGrandParent(parentLi)
    }

    if (element) element.scrollIntoView()
  }, [pathname])

  const currentNav = useMemo(() => {
    let navData = docsLeftNav
    if (
      experiments?.studioEnableMultiparty ===
        'trmt_studio_enable_multiparty_all' ||
      experiments?.studioEnableMultiparty ===
        'trmt_studio_enable_multiparty_standard'
    ) {
      navData = modifyMultipartyPagesUrlsWithExperiment(navData)
    }
    return findLeftNav(navData, pathname)
  }, [pathname])

  const getTopLevelUrl = (currentNav: { parentUrl: any; url: any }) => {
    if (!currentNav) {
      return undefined
    }
    return currentNav?.parentUrl || currentNav?.url
  }

  return (
    <>
      <Sidebar
        isFullWidth={isFullWidth}
        isLimitedRelease={undefined}
        className={cls({ ['sidebar-hidden-icons']: !hasIcons }, className)}
      >
        {hasIcons &&
          iconNav.map(({ title = '', href = '', icon }) => (
            <Sidebar.SidebarIconWrapperLink
              href={href}
              key={href}
              title={title}
            >
              <Sidebar.SidebarIcon
                key={`${title}-${href}`}
                isActive={urlMatch(getTopLevelUrl(currentNav), href)}
              >
                {icon}
              </Sidebar.SidebarIcon>
              <Sidebar.SidebarIconTitle>{title}</Sidebar.SidebarIconTitle>
            </Sidebar.SidebarIconWrapperLink>
          ))}

        <Sidebar.SidebarMenu>
          {currentNav &&
            [currentNav].map(
              ({
                title = 'PayPal',
                url = '#',
                amplitude = null,
                children = [],
              } = {}) => (
                <>
                  <a
                    className="sidebar-title docsHeader-title"
                    key={url}
                    href={url}
                  >
                    {(title && title) || ''}
                  </a>
                  {children &&
                    children.map((child: any, index: any) => (
                      <MenuItem
                        key={index}
                        item={child}
                        countrySelection={countrySelection}
                        paymentSelection={paymentSelection}
                      />
                    ))}
                </>
              ),
            )}
        </Sidebar.SidebarMenu>
      </Sidebar>
    </>
  )
}

LeftMdNavigation.displayName = 'LeftMdNavigation'

export default LeftMdNavigation
