/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable mdx/no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Helmet } from 'react-helmet'

import ParentHeader from '../components/layout/Header/ParentHeader'
import LeftNavigation from '../components/layout/LeftNavigation'
import RightNavigation from '../components/layout/RightNavigation'
import Footer from '../components/layout/Footer/Footer'
import IsAPIDeprecated from '../components/layout/SwaggerAPI/IsAPIDeprecated'
import { getRequest } from '../utils/request'
import { useApplicationContext } from '../provider'
import { withScriptLoader } from '../components/hoc'
import { useWindowSize } from '../hooks/useWindowSize/useWindowSize'

import UiKitHeaderContainer from '../components/layout/Header/UiKitHeaderContainer'
import { Container } from '@paypalcorp/pp-react-grid'

const Layout = ({
  rightNav,
  leftNav,
  children,
  data_type,
  layout,
  leftNavBar,
  location,
  onMount,
  isDeprecated,
  apiLatestVersion,
  apiLatestVersionPath,
  title = 'PayPal Developer',
  description,
  keywords,
  contentType,
  productStatus,
  apiVersion,
  sdkVersion,
  aIntegration,
  fileAbsolutePath,
  inverseHeaderScheme = false,
  hideRightNav,
}) => {
  /* TODO: as a new div with id body has been created, correct references in js/css to body element having layout classes to body id, we can also change the id in future if needed. */

  const { user, setUser, isScriptLoaded, Cookies, setUserMetaData } =
    useApplicationContext() //add elmo data
  const windowSize = useWindowSize()
  let oldHeaderShow = false
  let isHomePage =
    typeof window !== 'undefined' &&
    window.location &&
    (window.location.pathname === '/home' ||
      window.location.pathname === '/home/')
  const [logoutURL, setLogoutURL] = useState('')
  const [hardLogoutURL, setHardLogoutURL] = useState('')

  const getUserInfo = async () => {
    try {
      const { data } = await getRequest('/apis/user')
      setUser(data)
    } catch (err) {
      console.log(err)
    }
  }

  const getUserMetaData = async () => {
    try {
      const response = await getRequest('/apis/vpns')
      const { data } = response.data
      setUserMetaData(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUserInfo()
    getUserMetaData()
    if (typeof document !== 'undefined') {
      const rootElement = document.documentElement
      if (isHomePage) {
        rootElement.style.fontSize = '14px'
      } else {
        rootElement.style.fontSize = '16px'
      }
    }
  }, [])

  useEffect(() => {
    if (isScriptLoaded) {
      window.app.initialize()
      // eslint-disable-next-line no-unused-expressions
      onMount && onMount()
      let origin =
        typeof window !== `undefined` &&
        window.__INITIALDATA__ &&
        window.__INITIALDATA__.isPaypal
      localStorage.setItem('origin', origin)
    }
  }, [isScriptLoaded])

  useEffect(() => {
    if (!isEmpty(user)) {
      const currentUrl = (location && location.href) || '#'
      setLogoutURL(
        user?.logout?.url?.replace(/PAGEURL/g, encodeURIComponent(currentUrl)),
      )
      if (user.webLLS) {
        setHardLogoutURL(
          user?.hardLogout?.url.replace(
            /PAGEURL/g,
            encodeURIComponent(currentUrl),
          ),
        )
      }
    }
  }, [location, user])

  const renderHeader = () => {
    if (typeof window !== 'undefined') {
      return (
        <UiKitHeaderContainer
          user={user}
          logoutURL={logoutURL}
          hardLogoutURL={hardLogoutURL}
        />
      )
    }
    return null
  }

  return (
    <div id="body" className={layout}>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{title}</title>
        <link
          rel="icon"
          sizes="32x32"
          href="https://www.paypalobjects.com/webstatic/developer/favicons/pp32.png"
        />
        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
        {contentType && <meta name="contentType" content={contentType} />}
        {productStatus && <meta name="productStatus" content={productStatus} />}
        {apiVersion && <meta name="apiVersion" content={apiVersion} />}
        {sdkVersion && <meta name="sdkVersion" content={sdkVersion} />}
        {title && <meta property="og:title" content={title} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {aIntegration && <meta name="aIntegration" content={aIntegration} />}
        {location?.pathname.includes('/archive') && (
          <meta name="robots" content="noindex" />
        )}
        {fileAbsolutePath?.includes('limited-release') && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      {renderHeader()}
      <div
        className={`${
          user.loggedIn &&
          !oldHeaderShow &&
          window.location.pathname !== '/home' &&
          window.location.pathname !== '/home/'
            ? 'userloggedIn videoPage_loggedIn'
            : ''
        }`}
      >
        {isDeprecated ? (
          <IsAPIDeprecated
            apiLatestVersion={apiLatestVersion}
            apiLatestVersionPath={apiLatestVersionPath}
          />
        ) : null}
        <div className={leftNavBar ? 'dx-flex dx-flex-new' : ''}>
          {leftNavBar
            ? windowSize.width > 1023 && (
                <LeftNavigation
                  location={location}
                  leftNav={leftNav}
                  data_type={data_type}
                />
              )
            : null}
          {children}
          {rightNav && !hideRightNav ? (
            <RightNavigation location={location} rightNav={rightNav} />
          ) : null}
        </div>
        <Footer inverseHeaderScheme={inverseHeaderScheme} hide={isHomePage} />
      </div>
    </div>
  )
}

export default withScriptLoader(Layout)
