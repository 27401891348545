import React, { useEffect } from 'react'

// import Header from './header'
//@ts-ignore
import OldHeader from './oldheader'
import NewHeader from './NewHeader'

interface ParentHeaderProps {
  user?: any
  location: Location
  headerType?: string
  inverseHeaderScheme: boolean
  leftNav: any
}

const ParentHeader: React.FC<ParentHeaderProps> = ({
  user = {},
  leftNav,
  location,
  headerType = 'old',
  inverseHeaderScheme,
}) => {
  const renderHeader = () => {
    if (headerType === 'new') {
      return (
        <NewHeader
          leftNav={leftNav}
          user={user}
          location={location}
          inverseHeaderScheme={inverseHeaderScheme}
        />
      )
    }
    return <OldHeader user={user} location={location} />
  }

  useEffect(() => {
    renderHeader()

    if (headerType === 'old') {
      const header = document.querySelector('#header')
      header && header.classList && header.classList.remove('hp-PPCP')
      const ppcpSecNav = document.querySelector('div .hp-PPCP-secNav')
      ppcpSecNav && ppcpSecNav.remove()
    }
  }, [headerType])

  return <>{renderHeader()}</>
}

export default ParentHeader
