import React, { ReactNode } from 'react'

export interface ISidebarIconWrapperLinkProps {
  href: string
  children: ReactNode | ReactNode
  title: string
}

export const SidebarIconWrapperLink: React.FC<ISidebarIconWrapperLinkProps> = ({
  href,
  children,
  title,
}) => {
  return (
    <li key={href}>
      <a href={href} title={`Navigate to ${title}`}>
        {children}
      </a>
    </li>
  )
}
