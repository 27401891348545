import { Link, navigate } from 'gatsby'
import React, { ReactNode } from 'react'
import { Arrow } from '../../../icons'
import '../../MenuItem/MultiLevel/MultiLevelCollapse/multilevel-collapse.css'

interface LimitedMultiLevelCollapseProps {
  onClick: () => void
  url: string
  title: string
  isExpanded: boolean
  children?: ReactNode | ReactNode[]
}

export const LimitedMultiLevelCollapse: React.FC<
  LimitedMultiLevelCollapseProps
> = ({ onClick, url, title, children, isExpanded }) => {
  const handleArrowClick = () => {
    navigate(url)
    onClick()
  }

  const renderTitles = () => {
    if (title === 'Pay Later Offers (US)') {
      return 'Pay Later Offers'
    } else if (title === 'Checkout Pay (PayPal)') {
      return 'Checkout Pay'
    }

    return title
  }

  return (
    <>
      <li className="multilevel-collapse-container" onClick={onClick}>
        <a
          href={url}
          style={{ zIndex: 10, width: '100%' }}
          className={
            isExpanded
              ? 'multilevel-collapse-title multilevel-collapse-open'
              : 'multilevel-collapse-title'
          }
        >
          {renderTitles()}
        </a>
        <div
          className="multilevel-arrow-icon-container"
          onClick={handleArrowClick}
        >
          <Arrow expanded={isExpanded} onClick={handleArrowClick} />
        </div>
      </li>
      {isExpanded && children && children}
    </>
  )
}
