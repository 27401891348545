import React, { useState, useEffect } from 'react'

import { Header, HeaderMobile, AuthHeader } from '@paypalcorp/developeruikit'
import {
  headerLinks,
  headerLinksWithServerSdk,
} from '../../../content/homeContent'
import { useWindowSize } from '../../../hooks/useWindowSize/useWindowSize'
import { Container, Row } from '@paypalcorp/pp-react-grid'
import { useLocation } from '@reach/router'
import { useApplicationContext } from '../../../provider'

const UiKitHeaderContainer = (user) => {
  const userLoggedIn = user && user.user.loggedIn
  const [SearchModule, setSearchModule] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const windowSize = useWindowSize()
  const location = useLocation()

  const { experiments } = useApplicationContext()
  const isAlgoliaSearch =
    experiments.aiSearch === 'Trmt_Developer_AI_Search' ||
    experiments.aiSearch_acctID === 'Trmt_Developer_AI_Search_AcctID'
  const isServerSdk =
    experiments.serverSdkExperience === 'Trmt_Server_Sdk_Portal' || false
  let headerMargin = 60

  if (location?.pathname !== '/home') {
    headerMargin = 70
  }

  const authLinks = [
    {
      label: 'Go to Dashboard',
      url: '',
      items: [
        {
          label: 'Business Dashboard',
          url: 'https://www.paypal.com/myaccount/summary',
        },
        {
          label: 'Developer Dashboard',
          url: 'https://developer.paypal.com/dashboard/',
        },
      ],
    },
    {
      label: 'Help',
      url: 'https://www.paypal.com/smarthelp/home',
    },
  ]

  const authDropdown = [
    {
      label: 'Dashboard',
      url: '/dashboard/',
    },
    {
      label: 'My Account',
      url: '/developer/accountStatus/',
    },
    {
      label: 'Logout',
      url: 'https://www.paypal.com/signout?returnUri=https%3A%2F%2Fdeveloper.paypal.com%2Fhome%2F',
    },
  ]

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setSearchModule(require('@paypalcorp/developersearchui'))
    }
  }, [])

  const fptiData = typeof window !== `undefined` && window.fpti

  const recordAnalytics = () => {
    if (fptiData && window.PAYPAL?.analytics?.instance && !isOpen) {
      window.PAYPAL.analytics.instance.record({
        data: {
          component: 'devdiscoverynodeweb',
          space_key: 'SKEXJE',
          event_name: `devexp_${window.fpti.page.replace(
            /[-:]/g,
            '_',
          )}_search_modal_shown`,
          e: 'cl',
          ...fptiData,
        },
      })
    }
    if (fptiData && window.PAYPAL?.analytics?.instance && isOpen) {
      window.PAYPAL.analytics.instance.record({
        data: {
          component: 'devdiscoverynodeweb',
          space_key: 'SKEXJE',
          event_name: `devexp_${window.fpti.page.replace(
            /[-:]/g,
            '_',
          )}_search_modal_closed`,
          e: 'cl',
          ...fptiData,
        },
      })
    }
  }

  const handleShowSearchModal = () => {
    setIsOpen((prev) => !prev)
    recordAnalytics()
  }
  const handleCloseModal = () => {
    setIsOpen(false)
    recordAnalytics()
  }

  const auth = {
    status: userLoggedIn ? 'logged-in' : 'logged-out',
    firstName: user.user.firstName ? user.user.firstName : '',
    lastName: user.user?.lastName ? user.user.lastName : '',
    userName: user.user?.username ? user.user?.username : '',
  }
  const headerFromUiKit = (
    <Container>
      <Row>
        <div
          className="outerHeaderWrapper"
          style={
            userLoggedIn ? { marginTop: `${headerMargin}px`, zIndex: 1 } : {}
          }
        >
          <Header
            links={isServerSdk ? headerLinksWithServerSdk : headerLinks}
            children={
              SearchModule &&
              (!isAlgoliaSearch ? (
                <SearchModule.Search
                  onOpen={handleShowSearchModal}
                  onClose={handleCloseModal}
                  clientFptiProps={{ component: 'devdocs', space_key: 'SKEXJE' }}
                />
              ) : (
                <SearchModule.AlgoliaSearch
                  fptiOptions={{ component: 'devdocs', space_key: 'SKEXJE' }}
                />
              ))
            }
            user={user}
            location={{ href: location.pathname }}
            auth={auth}
            hasSignUpButton={true}
            signUpLink=""
          />
        </div>
      </Row>
    </Container>
  )

  return (
    <>
      {windowSize.width <= 1300 && (
        <div className="mobileMenu">
          <HeaderMobile
            links={isServerSdk ? headerLinksWithServerSdk : headerLinks}
            children={
              SearchModule &&
              (!isAlgoliaSearch ? (
                <SearchModule.Search
                  onOpen={handleShowSearchModal}
                  onClose={handleCloseModal}
                  clientFptiProps={{ component: 'devdocs', space_key: 'SKEXJE' }}
                />
              ) : (
                <SearchModule.AlgoliaSearch
                  indexName={{
                    PayPal: 'Prod_PayPal_DevEx_V1',
                    Braintree: 'BTDOCS_BTDOCSNODEWEB',
                  }}
                  fptiOptions={{ component: 'devdocs', space_key: 'SKEXJE' }}
                />
              ))
            }
            user={{
              ...(user, user.user),
              auth,
              links: {
                items: [
                  {
                    label: 'Dashboard',
                    url: 'https://developer.paypal.com/dashboard/',
                  },
                  {
                    label: 'My Account',
                    url: 'https://developer.paypal.com/developer/accountStatus/',
                  },
                  {
                    label: 'Log Out',
                    url: 'https://www.paypal.com/signout?returnUri=https%3A%2F%2Fdeveloper.paypal.com%2Fhome&_ga=1.261079885.1562070675.1697895797',
                  },
                ],
              },
            }}
            location={{ href: location.pathname }}
            hasSignUpButton={true}
            signUpLink=""
          />
        </div>
      )}
      {windowSize.width > 1300 && (
        <>
          {userLoggedIn ? (
            <div className="authHeaderHomePage">
              <AuthHeader
                links={authLinks}
                authDropdown={authDropdown}
                auth={auth}
              />
              <div>{headerFromUiKit}</div>
            </div>
          ) : (
            <div>{headerFromUiKit}</div>
          )}
        </>
      )}
    </>
  )
}

export default UiKitHeaderContainer
