import React from 'react'
import '../styles/sidebar-icon-link.css'

export interface ISidebarIconProps {
  isActive?: boolean
}

export const SidebarIcon: React.FC<ISidebarIconProps> = (props) => {
  return (
    <>
      <span
        className={`sidebar-icon ${
          props.isActive ? 'sidebar-icon-active' : ''
        }`}
      >
        {props.children}
      </span>
    </>
  )
}
