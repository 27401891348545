/**
 * @description Helper function that returns true if the current
 * item meets all the defined conditions to be considered as a parent menu item.
 * @param item
 * @returns boolean
 **/
export function hasChildren(item: any) {
  const { children } = item

  if (!Array.isArray(children) || children.length === 0) {
    return false
  }

  return true
}
