import React from 'react'

const Close = ({ style }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.06" width="36" height="36" rx="18" fill="#F1EFEA" />
      <path
        d="M0 18C0 8.05887 8.05887 0 18 0V0C27.9411 0 36 8.05887 36 18V18C36 27.9411 27.9411 36 18 36V36C8.05887 36 0 27.9411 0 18V18Z"
        fill="#D1F1FF"
      />
      <rect opacity="0.06" width="36" height="36" rx="18" fill="#F1EFEA" />
      <path
        d="M23.6066 12.9451C23.9971 12.5002 23.9971 11.7787 23.6066 11.3337C23.2161 10.8888 22.5829 10.8888 22.1924 11.3337L17.9503 16.1673L13.7083 11.3339C13.3178 10.8889 12.6846 10.8889 12.2941 11.3339C11.9036 11.7789 11.9036 12.5003 12.2941 12.9453L16.5361 17.7787L12.2929 22.6135C11.9024 23.0585 11.9024 23.7799 12.2929 24.2249C12.6834 24.6699 13.3166 24.6699 13.7071 24.2249L17.9503 19.3901L22.1936 24.2251C22.5841 24.6701 23.2173 24.6701 23.6078 24.2251C23.9983 23.7801 23.9983 23.0587 23.6078 22.6137L19.3645 17.7787L23.6066 12.9451Z"
        fill="#0C0C0D"
      />
    </svg>
  )
}

export default Close
