export const heroSlides = [
  {
    sectionTitle: 'PayPal Developer Day for Fastlane',
    sectionDescription:
      '<p>Accelerate your integration of Fastlane during this exclusive, technical event on August 20 and 21, 2024 at PayPal headquarters in San Jose, CA</p>',
    imgUrl: 'https://www.paypalobjects.com/devdoc/SJCampus500x350.png  ',
    altText: 'paypal-campus',
    button: true,
    btnSize: 'large',
    btnBackgroundColor: '#003087',
    btnBorderColor: '',
    btnColor: '#ffffff',
    btnLabel: 'Register Now',
    url: 'https://paypalevents.swoogo.com/developerday2024/home',
  },
  {
    sectionTitle: 'One integration, many payment methods',
    sectionDescription:
      '<p>Introducing a new interactive experience to accelerate your Checkout integration! Build quickly and efficiently.</p>',
    imgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/Studio_devHome_small.png',
    altText: 'ppcp-screenshot',
    button: true,
    btnSize: 'large',
    btnBackgroundColor: '#003087',
    btnBorderColor: '',
    btnColor: '#ffffff',
    btnLabel: 'Get started',
    url: '/studio/checkout/standard/integrate',
  },
  {
    sectionTitle: 'Customize your enterprise payment solution with Braintree',
    sectionDescription:
      "<p>Discover resources and tools for developers to integrate Braintree's global payments platform.</p>",
    imgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/carousel-image-2.svg',
    altText: 'direct-seamless-screenshot',
    button: true,
    btnSize: 'large',
    btnBackgroundColor: '#003087',
    btnBorderColor: '',
    btnColor: '#ffffff',
    btnLabel: 'Get started',
    url: '/braintree/docs',
  },
  {
    sectionTitle: 'Build your own marketplace or e-commerce platform',
    sectionDescription:
      '<p>Learn how PayPal helps marketplaces and commerce platforms set up flexible payment processing.</p>',
    imgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/carousel-image-3.svg',
    altText: '',
    button: true,
    btnSize: 'large',
    btnBackgroundColor: '#003087',
    btnBorderColor: '',
    btnColor: '#ffffff',
    btnLabel: 'Get started',
    url: '/docs/multiparty/',
  },
]

export const ppcpPaymentMethods = [
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-paypal.svg',
    title: 'PayPal',
    text: 'All-in-one checkout solution to accept PayPal, Venmo, Pay Later, and more',
    url: '/docs/checkout/standard/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-card-processing.svg',
    title: 'Card Processing',
    text: 'Process PayPal and major credit and debit cards with a more advanced integration',
    url: '/docs/checkout/advanced/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-pay-later.svg',
    title: 'Pay Later',
    text: 'Let customers buy now and pay later at checkout',
    url: '/docs/checkout/pay-later/us/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-venmo.svg',
    title: 'Venmo',
    text: 'Allow your customers to checkout with Venmo (US only)',
    url: '/docs/checkout/pay-with-venmo/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-apple-pay.svg',
    title: 'Apple Pay',
    text: 'Set up a card payment form to accept debit and credit cards',
    url: '/docs/checkout/apm/apple-pay/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-google-pay.svg',
    title: 'Google Pay',
    text: 'Accept payments from bank accounts, digital wallets, and local payment methods',
    url: '/docs/checkout/apm/google-pay/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-local-payment-methods.svg',
    title: 'Local Payment Methods',
    text: 'Let your customers pay with payment methods specific to their region',
    url: '/docs/checkout/apm/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-pyusd.svg',
    title: 'PYUSD',
    text: "Learn about PYUSD, PayPal's new cryptocurrency",
    url: 'https://www.paypal.com/us/cshelp/article/what-is-paypal-usd-pyusd-help1005',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-multiparty-solutions.svg',
    title: 'Multiparty Solutions',
    text: 'Create an onboarding and payments solution for your marketplace or payments platform',
    url: '/docs/multiparty/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
]

export const ppcpPaymentCapabilities = [
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-subscriptions.svg',
    title: 'Subscriptions',
    text: 'Create subscriptions to bill customers at regular intervals',
    url: '/docs/subscriptions/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-invoicing.svg',
    title: 'Invoicing',
    text: 'Send an online invoice that customers can pay using their preferred payment method',
    url: '/docs/invoicing/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-donations.svg',
    title: 'Donations',
    text: 'Accept donations directly on your website',
    url: '/sdk/donate/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-mass-payments.svg',
    title: 'Mass Payments',
    text: 'Send payouts to contract workers, claimants, freelancers, and sellers around the world',
    url: '/docs/payouts/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-pos-systems.svg',
    title: 'POS Systems',
    text: 'Set up a ready-made point-of-sale solution or integrate a payment solution to your service',
    url: '/docs/in-person/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-save-payment-methods.svg',
    title: 'Save Payment Methods',
    text: "Save payment methods for later so payers don't have to enter details for future transactions",
    url: '/docs/checkout/save-payment-methods/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
]

export const ppcpAddOnServices = [
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-disputes.svg',
    title: 'Disputes',
    text: 'Automate your dispute management, from initial claim to final resolution',
    url: '/docs/disputes/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-reporting.svg',
    title: 'Reporting',
    text: 'Get transaction-level insights to help manage your day-to-day operations',
    url: '/docs/reports/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/ppcp-identity.svg',
    title: 'Identity',
    text: 'Let your customers log in to your website using their PayPal credentials',
    url: '/docs/log-in-with-paypal/',
    linkText: 'Read documentation',
    includeArrow: true,
  },
]

export const toolRowBlocks = [
  {
    icon: 'https://www.paypalobjects.com/devdoc/NewHomepage/svg/toolrow-codespaces.svg',
    label: 'Codespaces',
    url: '/api/rest/sandbox/codespaces/',
  },
  {
    icon: 'https://www.paypalobjects.com/devdoc/NewHomepage/svg/toolrow-vscode.svg',
    label: 'VSCode',
    url: '/tools/vscode',
  },
  {
    icon: 'https://www.paypalobjects.com/devdoc/NewHomepage/svg/toolrow-postman.svg',
    label: 'Postman',
    url: '/api/rest/postman',
  },
]

export const communityCTASections = [
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/community-learn.svg',
    title: 'Learn about our products',
    htmlText:
      '<p>Read in-depth <a href="/community/blog">blogs</a> or watch <a href="/video/home/">videos</a> and expand your knowledge on all of PayPals products</p>',
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/community-connect.svg',
    title: 'Connect with developers',
    htmlText:
      '<p>Join our <a href="/community/">community</a> to learn from <a href="/community/champions/">experts</a>, attend exciting <a href="/community/events/">events</a>, and hear about the latest updates</p>',
  },
  {
    iconSvgUrl:
      'https://www.paypalobjects.com/devdoc/NewHomepage/svg/community-support.svg',
    title: 'Get support from your peers',
    htmlText:
      '<p>Ask for <a href="/support/">support</a> while troubleshooting</p>',
  },
]

export const siteMapSections = [
  {
    sectionHeading: 'Docs',
    sectionLinks: [
      {
        linkText: 'Online',
        linkUrl: '/docs/online/',
      },
      {
        linkText: 'In-Person',
        linkUrl: '/docs/in-person/',
      },
      {
        linkText: 'Multiparty',
        linkUrl: '/docs/multiparty/',
      },
      {
        linkText: '3rd-party',
        linkUrl: '/docs/3rd-party/',
      },
      {
        linkText: 'Payouts',
        linkUrl: '/docs/payouts/',
      },
      {
        linkText: 'Disputes',
        linkUrl: '/docs/disputes/',
      },
      {
        linkText: 'Reports',
        linkUrl: '/docs/reports/',
      },
      {
        linkText: 'Identity',
        linkUrl: '/docs/log-in-with-paypal/',
      },
      {
        linkText: 'Tracking',
        linkUrl: '/docs/tracking/',
      },
      {
        linkText: 'Archive',
        linkUrl: '/docs/archive',
      },
    ],
  },
  {
    sectionHeading: 'Tools',
    sectionLinks: [
      {
        linkText: 'Sandbox testing',
        linkUrl: '/tools/sandbox/',
      },
      {
        linkText: 'API executor',
        linkUrl: 'https://www.paypal.com/apex/home',
      },
      {
        linkText: 'Demo portal',
        linkUrl: 'https://demo.paypal.com/us/demo/home',
      },
      {
        linkText: 'Negative testing',
        linkUrl: '/tools/sandbox/negative-testing/',
      },
      {
        linkText: 'Codespaces',
        linkUrl: '/api/rest/sandbox/codespaces/',
      },
      {
        linkText: 'VS Code Extension',
        linkUrl: '/tools/vscode',
      },
      {
        linkText: 'Credit card generator',
        linkUrl: '/api/rest/sandbox/card-testing/',
      },
      {
        linkText: 'Webhooks',
        linkUrl: '/api/rest/webhooks/',
      },
      {
        linkText: 'API Status',
        linkUrl: 'https://www.paypal-status.com/api/production',
      },
      {
        linkText: 'Secure file transfer',
        linkUrl: '/tools/dropzone',
      },
    ],
  },
  {
    sectionHeading: 'APIs & SDKs',
    sectionLinks: [
      {
        linkText: 'REST APIs',
        linkUrl: '/api/rest/',
      },
      {
        linkText: 'JavaScript SDK',
        linkUrl: '/sdk/js/',
      },
      {
        linkText: 'NVP/SOAP APIs',
        linkUrl: '/api/nvp-soap/',
      },
      {
        linkText: 'Donate SDK',
        linkUrl: '/sdk/donate/',
      },
      {
        linkText: 'Braintree Graph',
        linkUrl: '/braintree/graphql/',
      },
    ],
  },
  {
    sectionHeading: 'Community',
    sectionLinks: [
      {
        linkText: 'Community home',
        linkUrl: '/community/home/',
      },
      {
        linkText: 'Blog',
        linkUrl: '/community/blog/',
      },
      {
        linkText: 'Events',
        linkUrl: '/community/events/',
      },
      {
        linkText: 'Champions',
        linkUrl: '/community/champions/',
      },
      {
        linkText: 'Videos',
        linkUrl: '/video/home/',
      },
      {
        linkText: 'Developer Program',
        linkUrl: '/developer-program/',
      },
    ],
  },
]

export const headerLinks = [
  {
    label: 'Docs',
    url: '',
    items: [
      {
        label: 'Online',
        url: '/docs/online/',
      },
      {
        label: 'In-Person',
        url: '/docs/in-person/',
      },
      {
        label: 'Multiparty',
        url: '/docs/multiparty/',
      },
      {
        label: '3rd Party',
        url: '/docs/3rd-party/',
      },
      {
        label: 'Payouts',
        url: '/docs/payouts/',
      },
      {
        label: 'Disputes',
        url: '/docs/disputes/',
      },
      {
        label: 'Reports',
        url: '/docs/reports/',
      },
      {
        label: 'Identity',
        url: '/docs/log-in-with-paypal/',
      },
      {
        label: 'Tracking',
        url: '/docs/tracking/',
      },
      {
        label: 'Archive',
        url: '/docs/archive',
      },
    ],
  },
  {
    label: 'API & SDK',
    url: '',
    items: [
      {
        label: 'REST APIs',
        url: '/api/rest/',
      },
      {
        label: 'JavaScript SDK',
        url: '/sdk/js/',
      },
      {
        label: 'Mobile SDKs',
        url: '/sdk/mobile/',
      },
      {
        label: 'NVP/SOAP APIs',
        url: '/api/nvp-soap/',
      },
      {
        label: 'Donate SDK',
        url: '/sdk/donate/',
      },
      {
        label: 'Braintree GraphQL API',
        url: '/braintree/graphql/',
      },
    ],
  },
  {
    label: 'Tools',
    url: '',
    items: [
      {
        label: 'Sandbox testing',
        url: '/tools/sandbox/',
      },
      {
        label: 'API executor',
        url: 'https://www.paypal.com/apex/home',
      },
      {
        label: 'Demo portal',
        url: 'https://demo.paypal.com/us/demo/home',
      },
      {
        label: 'Negative testing',
        url: '/tools/sandbox/negative-testing/',
      },
      {
        label: 'Codespaces',
        url: '/api/rest/sandbox/codespaces/',
      },
      {
        label: 'VS Code Extension',
        url: '/tools/vscode',
      },
      {
        label: 'Credit card generator',
        url: '/api/rest/sandbox/card-testing/',
      },
      {
        label: 'Webhooks',
        url: '/api/rest/webhooks/',
      },
      {
        label: 'API Status',
        url: 'https://www.paypal-status.com/api/production',
      },
      {
        label: 'Secure file transfer',
        url: '/tools/dropzone',
      },
    ],
  },
  {
    label: 'Community',
    url: '',
    items: [
      {
        label: 'Community',
        url: '/community/home/',
      },
      {
        label: 'Blog',
        url: '/community/blog/',
      },
      {
        label: 'Events',
        url: '/community/events/',
      },
      {
        label: 'Champions',
        url: '/community/champions/',
      },
      {
        label: 'Videos',
        url: '/video/home/',
      },
      {
        label: 'Developer Program',
        url: '/developer-program/',
      },
    ],
  },
  {
    label: 'Support',
    url: '/support/',
  },
  {
    label: 'PayPal.com',
    url: 'https://www.paypal.com/',
  },
]

export const headerLinksWithServerSdk = [
  {
    label: 'Docs',
    url: '',
    items: [
      {
        label: 'Online',
        url: '/docs/online/',
      },
      {
        label: 'In-Person',
        url: '/docs/in-person/',
      },
      {
        label: 'Multiparty',
        url: '/docs/multiparty/',
      },
      {
        label: '3rd Party',
        url: '/docs/3rd-party/',
      },
      {
        label: 'Payouts',
        url: '/docs/payouts/',
      },
      {
        label: 'Disputes',
        url: '/docs/disputes/',
      },
      {
        label: 'Reports',
        url: '/docs/reports/',
      },
      {
        label: 'Identity',
        url: '/docs/log-in-with-paypal/',
      },
      {
        label: 'Tracking',
        url: '/docs/tracking/',
      },
      {
        label: 'Archive',
        url: '/docs/archive',
      },
    ],
  },
  {
    label: 'API & SDK',
    url: '',
    items: [
      {
        label: 'REST APIs',
        url: '/api/rest/',
      },
      {
        label: 'Server SDK',
        url: '/serversdk/http/getting-started/how-to-get-started/',
      },
      {
        label: 'JavaScript SDK',
        url: '/sdk/js/',
      },
      {
        linkText: 'Mobile SDKs',
        linkUrl: '/sdk/mobile/',
      },
      {
        label: 'NVP/SOAP APIs',
        url: '/api/nvp-soap/',
      },
      {
        label: 'Donate SDK',
        url: '/sdk/donate/',
      },
      {
        label: 'Braintree GraphQL API',
        url: '/braintree/graphql/',
      },
    ],
  },
  {
    label: 'Tools',
    url: '',
    items: [
      {
        label: 'Sandbox testing',
        url: '/tools/sandbox/',
      },
      {
        label: 'API executor',
        url: 'https://www.paypal.com/apex/home',
      },
      {
        label: 'Demo portal',
        url: 'https://demo.paypal.com/us/demo/home',
      },
      {
        label: 'Negative testing',
        url: '/tools/sandbox/negative-testing/',
      },
      {
        label: 'Codespaces',
        url: '/api/rest/sandbox/codespaces/',
      },
      {
        label: 'VS Code Extension',
        url: '/tools/vscode',
      },
      {
        label: 'Credit card generator',
        url: '/api/rest/sandbox/card-testing/',
      },
      {
        label: 'Webhooks',
        url: '/api/rest/webhooks/',
      },
      {
        label: 'API Status',
        url: 'https://www.paypal-status.com/api/production',
      },
      {
        label: 'Secure file transfer',
        url: '/tools/dropzone',
      },
    ],
  },
  {
    label: 'Community',
    url: '',
    items: [
      {
        label: 'Community',
        url: '/community/home/',
      },
      {
        label: 'Blog',
        url: '/community/blog/',
      },
      {
        label: 'Events',
        url: '/community/events/',
      },
      {
        label: 'Champions',
        url: '/community/champions/',
      },
      {
        label: 'Videos',
        url: '/video/home/',
      },
      {
        label: 'Developer Program',
        url: '/developer-program/',
      },
    ],
  },
  {
    label: 'Support',
    url: '/support/',
  },
  {
    label: 'PayPal.com',
    url: 'https://www.paypal.com/',
  },
]

export const integrationOptions = [
  {
    title: 'No Code',
    content:
      '<p>Configure and Go</p><ul><li>Configure payment button</li><li>Copy and paste code</li><li>Test a payment</li></ul>',
    link: {
      label: 'Get started',
      url: '/studio/checkout/no-code/',
    },
  },
  {
    title: 'Mobile SDKs',
    content:
      '<p>Explore our mobile SDKs</p><ul><li>Native iOS and Android SDKs</li><li>Check out with PayPal, credit, and debit cards</li><li>Save payment methods for faster checkout</li></ul>',
    link: {
      label: 'Get started',
      url: '/sdk/mobile/',
    },
  },
  {
    title: 'APIs',
    content:
      '<p>Explore our REST APIs</p><ul><li>Integrate front-end and back-end</li><li>Preview and test</li><li>Download code or export to IDE</li></ul>',
    link: {
      label: 'Get started',
      url: '/api/rest/',
    },
  },
  {
    title: 'JavaScript SDK',
    content:
      '<p>Explore our JavaScript SDK</p><ul><li>Configure payment buttons and card fields</li><li>Preview and test</li><li>Download code or export to IDE</li></ul>',
    link: {
      label: 'Get started',
      url: '/sdk/js/',
    },
  },
]

export const footerLinks = [
  { label: 'Reference', url: '/reference', externalLink: false },
  {
    label: 'PayPal.com',
    url: 'https://www.paypal.com/',
    externalLink: true,
  },
  {
    label: 'Privacy',
    url: 'https://www.paypal.com/myaccount/privacy/privacyhub',
    externalLink: true,
  },
  {
    label: 'Cookies',
    url: 'https://www.paypal.com/myaccount/privacy/cookiePrefs',
    externalLink: true,
  },
  {
    label: 'Support',
    url: '/support/',
    externalLink: false,
  },
  {
    label: 'Legal',
    url: 'https://www.paypal.com/us/webapps/mpp/ua/legalhub-full',
    externalLink: true,
  },
  {
    label: 'Contact',
    url: 'https://www.paypal.com/us/smarthelp/contact-us',
    externalLink: true,
  },
]
