import React, { useState, useEffect, useLayoutEffect } from 'react'
// import { paramCase } from 'change-case'
import { filter, isEmpty } from 'lodash'
import { useApplicationContext } from '../../../provider'
import './index.css'

// Filter all the elements that do not pass the criteria; (see also, on this page, or next in the h2 elements)
const filterRightNav = (rightNav) => {
  return filter(rightNav, ({ content }) => {
    const navLower = content.toLowerCase().trim()
    const filter_list = ['see also', 'on this page', 'additional information']
    if (filter_list.includes(navLower) || navLower.includes('next')) {
      return false
    }
    return true
  })
}

// Generates Right navigation panel
const RightNavigation = (props) => {
  let filteredRightNav = filterRightNav(props.rightNav)
  const { user, rightNav } = useApplicationContext()
  const userLoggedOut = !user?.loggedIn
  const [activeNav, setActiveNav] = useState('')

  useEffect(() => {
    setActiveNav(rightNav)
  }, [rightNav])

  useEffect(() => {
    let rightNavState = location.hash && location.hash.substring(1)
    setActiveNav((rightNavState && rightNavState) || '')
  }, [])

  const activeNavHandler = (e) => {
    filteredRightNav.map((element) => {
      if (element && element.content === ' ' + e.target.outerText) {
        setActiveNav(element.id)
      }
    })
  }

  if (filteredRightNav.length !== 0) {
    return (
      <div
        className={
          !userLoggedOut
            ? 'rightNav-container rightNav-authed'
            : 'rightNav-container'
        }
      >
        <div
          className={
            !userLoggedOut
              ? 'rightNav-wrapper rightNav-wrapper-authed'
              : 'rightNav-wrapper'
          }
        >
          <p className="rightNav-heading">On this page</p>
          <div className="rightNav-anchorContainer">
            <div className="rightNav-anchorLeftBorder" />
            <div className="rightNav-anchorsWrapper">
              {filteredRightNav.map(({ content, id }) => {
                const replaceId = id
                const isActive = activeNav === replaceId
                let anchorClass = isActive
                  ? 'rightNav-anchor' + ' ' + 'rightNav-active'
                  : 'rightNav-anchor'
                return (
                  <div className="rightNav-leftNav" key={replaceId}>
                    {isActive && <div className="rightNav-bluePill" />}
                    <a
                      key={id}
                      className={anchorClass}
                      href={`#${replaceId}`}
                      onClick={activeNavHandler}
                    >
                      <span className="rightNav-spanTag">{content}</span>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="rightNav-container">
      <div className="rightNav-wrapper" />
    </div>
  )
}
export default RightNavigation
