import React, { useRef, useEffect } from 'react'
import styles from './DropdownListItemLink.module.css'
import { Badge } from '@paypalcorp/pp-react'

interface DropdownListItemLinkProps {
  item: any
  active: boolean
  parent: string
}

export const DropdownListItemLink: React.FC<DropdownListItemLinkProps> = ({
  item,
  active,
  parent,
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (linkRef.current && active) {
      linkRef.current.focus()
    }
  }, [linkRef, active])

  const getClassName = () => {
    if (parent === 'profileDropdown') {
      return styles.profileDropdown
    }
    if (parent === 'dashboardDropdown') {
      return styles.dashboardDropdown
    }
    if (parent === 'navigationButton') {
      return styles.navigationButton
    }
  }

  function blankOrSelf(item) {
    if (item.url && typeof item.url !== 'string') {
      return '_self'
    }
    return item.url &&
      item.url.startsWith('http') &&
      !item.url.includes('developer.paypal.com')
      ? '_blank'
      : '_self'
  }

  return (
    <a
      key={item.title.trim()}
      href={item.url}
      ref={linkRef}
      className={`${getClassName()} ${item.classNames}`}
      data-enable-spinner={item.dataEnableSpinner}
      data-js={item.dataJS}
      data-bypass={item.dataBypass}
      target={blankOrSelf(item)}
      rel="noreferrer"
    >
      {item.title}

      {item.isNew && (
        <Badge className={styles.badgeNew} type="feature">
          New
        </Badge>
      )}
    </a>
  )
}
