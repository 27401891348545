/* eslint-disable no-console */
import React, { useEffect } from 'react'
import { useApplicationContext } from '../../provider'
import { loadScript } from '../../utils/scriptLoader'
import { withAssetPrefix } from 'gatsby'
const paths = [
  '/docs/checkout',
  '/limited-release/commerce-platform/v2/payment/checkout',
  '/limited-release/commerce-platform/v3/payment/checkout',
]
const lipppath = [
  '/docs/log-in-with-paypal/reference/button-js-builder/',
  '/docs/log-in-with-paypal/integrate/generate-button/',
]

export default (WrappendComponent) => {
  return function WithScriptLoader(props) {
    const { isPPSDKLoaded } = useApplicationContext()

    const loadScripts = async () => {
      try {
        if (isPPSDKLoaded)
          await loadScript({
            src: withAssetPrefix('/jscript/checkout/checkout.js'),
          })
      } catch (err) {
        console.log('SCRIPT LOADING ERROR')
      }
    }
    const loadLippScript = async () => {
      try {
        await loadScript({
          src: withAssetPrefix(
            '/jscript/pages/docs/integration/direct/identity/api.js',
          ),
        })
        await loadScript({
          src: withAssetPrefix(
            '/jscript/pages/docs/integration/direct/identity/lipp-button.js',
          ),
        })
      } catch (err) {
        console.log('SCRIPT LOADING ERROR')
      }
    }

    useEffect(() => {
      if (
        props.location &&
        (paths.includes(props.location.pathname) ||
          paths.includes(props.location.pathname.slice(0, -1)))
      ) {
        loadScripts()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPPSDKLoaded])

    useEffect(() => {
      if (
        props.location &&
        (lipppath.includes(props.location.pathname) ||
          lipppath.includes(props.location.pathname.slice(0, -1)))
      ) {
        loadLippScript()
      }
    })

    return <WrappendComponent {...props} />
  }
}
