import React, { useState } from 'react'
import styles from './DashboardDropdown.module.css'
import { DropdownListItemLink } from '../DropdownListItemLink'
import { traverseListWithKeyboard } from '../utils/keyboardNavigation'

const DashboardDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [cursor, setCursor] = useState<any>(null)

  let itemList = [
    {
      title: 'Business Dashboard',
      classNames: styles.dropdownLinkDashboard,
      dataEnableSpinner: 'https://www.paypal.com/myaccount/summary',
      url: 'https://www.paypal.com/myaccount/summary',
      dataJS: null,
      dataBypass: null,
    },
    {
      title: 'Developer Dashboard',
      classNames: styles.dropdownLinkMyAccount,
      dataEnableSpinner: 'https://developer.paypal.com/dashboard/',
      url: 'https://developer.paypal.com/dashboard/',
      dataJS: null,
      dataBypass: null,
    },
  ]

  return (
    <div
      className={`${isOpen && styles.dashboardDisplayFocused} dashboardWrapper`}
      tabIndex={0}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
      onKeyDown={(e) => traverseListWithKeyboard(e, itemList, setCursor)}
    >
      <div className="dashboard_link-title inlineBlock">Go to Dashboard</div>

      <div
        className={`${styles.dropdown} ${
          isOpen ? styles.openDropdown : styles.closedDropdown
        }`}
      >
        <ul className={styles.DashboardDropdownList}>
          {itemList.map((item, i) => (
            <li className={styles.listItem} key={i}>
              <DropdownListItemLink
                item={item}
                active={i === cursor}
                parent="dashboardDropdown"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default DashboardDropdown
