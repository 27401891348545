import cls from 'classnames'
import React, { useState } from 'react'
import '../../MenuItem/MultiLevel/MultiLevelCollapse/multilevel-collapse.css'
import '../../MenuItem/SingleLevel/single-level.css'
import { Sidebar } from '../../Sidebar'
import { LimitedMultiLevelCollapse } from './limited-multilevel-collapse'

interface PageItems {
  routes: any
  title: string
  apihref: string
  definitions: any
  hasError: boolean
}

interface LimitedReleaseNavProps {
  index: string | number
  page: PageItems
  pathname: any
  hash: any
  showLeftNav: any
  isFullWidth: any
}

export const LimitedReleaseNav: React.FC<LimitedReleaseNavProps> = ({
  page: { routes, title, apihref, definitions, hasError },
  index,
  pathname,
  hash,
  showLeftNav,
  isFullWidth,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isDefinitionExpanded, setIsDefinitionExpanded] = useState(false)

  const closeMobileMenu = () => {
    isFullWidth && showLeftNav(false)
  }

  return (
    <>
      {pathname === `/${apihref}/` && (
        <Sidebar.SidebarMenu>
          {Object.keys(routes).map((key) => {
            const hashValue = `#${routes[key][0].name}`
            return (
              <LimitedMultiLevelCollapse
                title={key}
                url={hashValue}
                isExpanded={isExpanded}
                onClick={() => setIsExpanded((prev) => !prev)}
                key={key}
              >
                {hash.includes(hashValue) && (
                  <div
                    className="multilevel-child-container"
                    style={{ marginTop: 0, marginLeft: 8 }}
                  >
                    {routes &&
                      routes[key].map((def: any) => (
                        <li key={def.summary}>
                          <a
                            href={
                              def.name &&
                              def.anchor &&
                              `#${def.name}_${def.anchor}`
                            }
                            title={title ? title : def.summary}
                            data-cy="api-hashlink"
                            onClick={() => closeMobileMenu()}
                            className={cls('single-level-link', {
                              ['single-level-link single-level-active-link']:
                                window.location.hash ===
                                `#${def.name}_${def.anchor}`,
                            })}
                          >
                            {def.summary}
                          </a>
                        </li>
                      ))}
                  </div>
                )}
              </LimitedMultiLevelCollapse>
            )
          })}
          <LimitedMultiLevelCollapse
            title="Common Object Definitions"
            url="#definitions"
            isExpanded={isDefinitionExpanded}
            onClick={() => setIsDefinitionExpanded((prev) => !prev)}
          >
            {hash.includes('#definition') && (
              <div
                className="multilevel-child-container"
                style={{ marginTop: 0, marginLeft: 8 }}
              >
                {definitions &&
                  definitions.map((def: any) => (
                    <a
                      key={def.name}
                      onClick={() => closeMobileMenu()}
                      href={`#definition-${def.href}`}
                      data-cy="api-hashlink"
                      title={def.name}
                      className={cls('single-level-link', {
                        ['single-level-link single-level-active-link']:
                          hash === `#definition-${def.href}`,
                      })}
                    >
                      {def.name}
                    </a>
                  ))}
              </div>
            )}
          </LimitedMultiLevelCollapse>
          {hasError && (
            <li>
              <a
                href={`${pathname}#errors`}
                data-cy="api-hashlink"
                title="Error Messages"
                onClick={() => closeMobileMenu()}
                className={cls('single-level-link', {
                  ['single-level-link single-level-active-link']:
                    window.location.href.includes(
                      `${window.location.origin}/${apihref}/#errors`,
                    ),
                  // 👆🏻 Hacky stuff because we are not getting the #errors
                  // hash, because of the data design, but this works well.
                })}
              >
                Error Messages
              </a>
            </li>
          )}
        </Sidebar.SidebarMenu>
      )}
    </>
  )
}
