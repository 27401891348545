import React from 'react'

const Burger = ({ height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C3 6.17157 3.67157 5.5 4.5 5.5H19.5C20.3284 5.5 21 6.17157 21 7C21 7.82843 20.3284 8.5 19.5 8.5H4.5C3.67157 8.5 3 7.82843 3 7ZM3 12C3 11.1716 3.67157 10.5 4.5 10.5H19.5C20.3284 10.5 21 11.1716 21 12C21 12.8284 20.3284 13.5 19.5 13.5H4.5C3.67157 13.5 3 12.8284 3 12ZM4.5 15.5C3.67157 15.5 3 16.1716 3 17C3 17.8284 3.67157 18.5 4.5 18.5H19.5C20.3284 18.5 21 17.8284 21 17C21 16.1716 20.3284 15.5 19.5 15.5H4.5Z"
        fill="#121661"
      />
    </svg>
  )
}

export default Burger
