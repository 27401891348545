import { withPrefix } from 'gatsby'
import { get } from 'lodash'
import React, { useEffect } from 'react'
import LeftJSONNavigation from './LeftJsonNavigation'
import LeftMdNavigation from './LeftMdNavigation'

const LeftNavigation = (props: any) => {
  useEffect(() => {
    const text = get(
      document.querySelectorAll('.dx-main-nav .tip-nav-active > a'),
      '[0].innerText',
    )
    let elm: any = document.querySelector('.dx-trigger-nav-2')
    if (elm) {
      elm.innerText = text
    }
  }, [])

  const pathname =
    withPrefix('/') !== '/'
      ? '/' + props.location.pathname.split('/').slice(2).join('/')
      : props.location.pathname

  const ComponentSelection =
    pathname.includes('limited-release') ||
    ['api', 'release-notes'].includes(pathname.split('/')[2])
      ? LeftJSONNavigation
      : LeftMdNavigation

  return <ComponentSelection {...props} />
}

LeftNavigation.displayName = 'LeftNavigation'

export default LeftNavigation
