import { Container, Row } from '@paypalcorp/pp-react'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import './navbar.css'
import { navLinks } from '../NavigationButtons/config'
import { useApplicationContext } from '../../../../provider'

interface FptiData {
  [key: string]: string | number | boolean
}
interface NavbarProps {
  user: any // TODO: define a new interface with all the values expected from the user and add use interface like UserProps
  location: Location
  inverseHeaderScheme: boolean
}

export const Navbar: React.FC<NavbarProps> = ({
  user,
  location,
  inverseHeaderScheme,
}) => {
  const [launcher, setLauncher] = useState<any>(null)
  const [loginURL, setLoginURL] = useState('')
  const [logoutURL, setLogoutURL] = useState('')
  const [userStatus, setUserStatus] = useState({
    status: 'logged-out',
    firstName: '',
  })
  const userLoggedOut = !user?.loggedIn
  const [searchID, setSearchID] = useState('')
  const { experiments } = useApplicationContext()
  const isAISearchActive =
    experiments.aiSearch === 'Trmt_Developer_AI_Search' ||
    experiments.aiSearch_acctID === 'Trmt_Developer_AI_Search_AcctID'

  const alternateColorFun = (() => {
    if (inverseHeaderScheme) return 'header-web-home'
    return 'header-web-not-home'
  })()

  const fptiData = typeof window !== `undefined` && window.fpti

  useEffect(() => {
    if (!isEmpty(user)) {
      setLoginURL(
        user.login.url.replace(
          /PAGEURL/g,
          encodeURIComponent((location && location.href) || '#'),
        ),
      )
      setLogoutURL(
        user.logout.url.replace(
          /PAGEURL/g,
          encodeURIComponent((location && location.href) || '#'),
        ),
      )
      if (!userLoggedOut) {
        setUserStatus({
          status: 'logged-in',
          firstName: user.firstName || user.username,
        })
      }
    }
  }, [location, user])

  useEffect(() => {
    setLauncher(
      typeof window !== `undefined`
        ? require('@paypalcorp/developersearchui')
        : null,
    )
  }, [])

  const queryString =
    typeof window !== 'undefined' ? window.location.search : ''

  const searchParams = new URLSearchParams(queryString)

  const aiQueryParam = searchParams.get('gen_ai_summary')

  const buttonContent = {
    label: 'Login',
    handleClick: () => {
      let options = {
        data: {
          page: fptiData && fptiData.page,
          pgrp: fptiData && fptiData.pgrp,
          component: 'devdiscoverynodeweb',
          space_key: 'SKEXJE',
          name: 'developer_loginbtn_click',
          tag: 'login-to-dashboard-click',
          link: loginURL,
          searchID: '',
        },
      }
      if (fptiData && aiQueryParam === 'true') {
        options.data.name = 'gen_ai_summary'
        options.data.searchID = searchID
      }
      if (window.PAYPAL?.analytics?.instance) {
        window.PAYPAL?.analytics?.instance?.recordClick(options)
      }
      if (fptiData && aiQueryParam === 'true') {
        options.data.name = 'gen_ai_summary'
        options.data.searchID = searchID
      }
      if (window.PAYPAL?.analytics?.instance) {
        window.PAYPAL?.analytics?.instance?.recordClick(options)
      }
      console.log('Success')
      window.location.href = loginURL
    },
  }

  return (
    <Container
      fluid={true}
      className={
        !userLoggedOut
          ? `header-web ${alternateColorFun} header-web-loggedIn`
          : `header-web ${alternateColorFun}`
      }
    >
      <Row>
        {launcher ? (
          <launcher.Header
            links={navLinks}
            buttonContent={buttonContent}
            showSearchToggle={isAISearchActive}
            auth={userStatus}
          />
        ) : null}
      </Row>
    </Container>
  )
}
