import React from 'react'
import { Link, withPrefix } from 'gatsby'
import { get } from 'lodash'

export const removeVersion = (path) => path.replace(/\/v\d+/, '')

export const trimSlashes = (path) => {
  if (!path) return path
  return path.replace(/^\/?([^\/]+(?:\/[^\/]+)*)\/?$/, '$1')
}

export const prependAppendSlashes = (path) =>
  path.replace(/^\/?([^\/]+(?:\/[^\/]+)*)\/?$/, '/$1/')

export const renderNav = ({ array, pathname: _pathname }) => {
  const pathname = _pathname.substr(-1) === '/' ? _pathname : _pathname + '/'
  const tempArray =
    array &&
    array.map((item = {}, key) => {
      let itemUrl = get(item, 'url', '')
      if (itemUrl) {
        itemUrl = item.url.substr(-1) === '/' ? item.url : item.url + '/'
      }
      const hasChildren = Boolean(item.children && item.children.length)
      const hasChildNode = Boolean(item.childNode)
      let liClass = ''
      if (pathname === itemUrl) {
        //checks for the last resource
        liClass = liClass + 'tip-nav-active'
      }
      const link = item.title ? <a href={item.url}>{item.title}</a> : null
      if (!item.url) return <h5 key={key}>{item.title}</h5>
      return (
        <>
          {link}
          {hasChildNode ? item.childNode : null}
          {hasChildren ? renderNav({ array: item.children, pathname }) : null}
        </>
      )
    })
  const result = tempArray

  return result
}

export const renderHashNav = (array, hash) => {
  const tempArray = array.map((item) => {
    const hashValue = `#${item.name}_${item.anchor}`
    const liClass =
      hash === hashValue
        ? 'tip-nav-hash tip-nav-active-hash tip-nav-active api-active'
        : 'tip-nav-hash tip-nav-active'
    return (
      <li key={item.operationId} className={liClass}>
        <a className="tip-nav-sub-hash sidebar-link" href={hashValue}>
          {item.summary}
        </a>
      </li>
    )
  })
  const result = <ul className="hashNav">{tempArray}</ul>

  return result
}

export const getPathnameWithPrefix = (pathname) =>
  withPrefix('/') !== '/'
    ? '/' + pathname.split('/').slice(2).join('/')
    : pathname

export const getGrandParent = (element) =>
  get(element, 'parentElement.parentElement')

export const getElement = (selector) =>
  document ? document.querySelector(selector) : null

export const urlMatch = (pathname, url) =>
  trimSlashes(pathname) === trimSlashes(url)

export const findLeftNav = (arr, pathname, obj = null) => {
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i]
    if (urlMatch(pathname, obj.url)) return obj
    if (obj.children) {
      const response = findLeftNav(obj.children, pathname, obj)
      if (response) return obj
    }
  }
}

export const modifyMultipartyPagesUrlsWithExperiment = (leftNavAllUrls) => {
  leftNavAllUrls.forEach((navPages) => {
    // Only process pages with a 'Multiparty' title
    if (navPages?.title === 'Multiparty Payments') {
      navPages.children?.forEach((navDropDownSection) => {
        // Check for 'Accept Payments' section
        if (navDropDownSection?.title === 'Accept Payments') {
          if (navDropDownSection.children?.length > 0) {
            navDropDownSection.url = '/studio/multiparty/checkout/standard/'
            let paypalObject = navDropDownSection.children[0]
            if (paypalObject?.title === 'PayPal') {
              paypalObject.url = '/studio/multiparty/checkout/standard/'
              // Add new pages for 'Getting Started' and 'Overview'
              const isExistOverviewPage = paypalObject.children?.find(
                ({ title }) => title === 'Overview',
              )
              if (!isExistOverviewPage) {
                paypalObject.children.unshift(
                  {
                    title: 'Overview',
                    url: '/studio/multiparty/checkout/standard/',
                  },
                  {
                    title: 'Getting Started',
                    url: '/studio/multiparty/checkout/standard/getstarted/',
                  },
                )
              }
              const integratePage = paypalObject.children?.find(
                ({ title }) => title === 'Integrate',
              )
              if (integratePage) {
                integratePage.url =
                  '/studio/multiparty/checkout/standard/integrate/'
              }
            }
          }
        }
      })
    }
  })
  return leftNavAllUrls
}

export const findBreadCrumbs = (arr, pathname, currentUrl, breadcrumbs) => {
  let breadcrumbsResult = []
  if (pathname === currentUrl) {
    return breadcrumbs
  }
  if (!arr) {
    return []
  }
  let currentNavArray = arr
  let i = 0
  while (i !== currentNavArray.length) {
    let breadcrumbsTemp = breadcrumbs
    breadcrumbsTemp.push({
      title: currentNavArray[i].title,
      url: currentNavArray[i].url,
    })
    let breadcrumbsCurrent = findBreadCrumbs(
      currentNavArray[i].children,
      pathname,
      currentNavArray[i].url,
      breadcrumbsTemp,
    )

    if (breadcrumbsCurrent.length !== 0) {
      breadcrumbsResult = breadcrumbsCurrent
      break
    }
    breadcrumbsTemp.pop()
    i++
  }
  return breadcrumbsResult
}
