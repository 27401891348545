import React, { useEffect, useRef, useState } from 'react'
import Link from '../../Header/NavigationButtons/NavLink'
import cls from 'classnames'
import './single-level.css'

interface SingleLevelProps {
  index: any
  url: string
  title: string
  countrySelection?: string
  paymentSelection?: string
  amplitude?: string
}

export const SingleLevel: React.FC<SingleLevelProps> = ({
  index,
  url,
  title,
  countrySelection,
  paymentSelection,
  amplitude,
}) => {
  const [hashLink, setHashLink] = useState('')
  const hashRef = useRef(null) as any

  const handleHashLink = () => setHashLink(hashRef.current?.href)

  useEffect(() => {
    handleHashLink()
  }, [hashRef.current?.href])

  const singleLevelLinkClasses = cls('single-level-link', {
    ['single-level-link single-level-active-link']:
      window.location.pathname === url,
  })

  const singleLevelHashLinkClasses = cls('single-level-link', {
    ['single-level-link single-level-active-link']:
      window.location.href === hashLink,
  })

  if (!url) {
    return (
      <li className="single-level-header" key={index}>
        {title}
      </li>
    )
  }

  return (
    <li key={index}>
      {url && url.includes('/#') ? (
        <a
          href={url}
          data-cy="api-hashlink"
          className={singleLevelHashLinkClasses}
          onClick={handleHashLink}
          ref={hashRef}
          data-amplitude={amplitude}
        >
          {title}
        </a>
      ) : (
        <a
          href={url}
          data-cy="api-link"
          amplitude={amplitude}
          className={singleLevelLinkClasses}
        >
          {title}
        </a>
      )}
    </li>
  )
}

SingleLevel.displayName = 'SingleLevel'
