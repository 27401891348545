import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { PaymentSelection } from '../../../../provider'
import { SidebarGlobalProps } from '../../Sidebar/types'
import { MultiLevelChild } from './MultiLevelChild/MultiLevelChild'
import { MultiLevelCollapse } from './MultiLevelCollapse/MultiLevelCollapse'

interface MultiLevelProps {
  title: string
  url: string
  children: SidebarGlobalProps[]
  countrySelection: string
  paymentSelection: PaymentSelection['value']
  amplitude: string
}

export const MultiLevel: React.FC<MultiLevelProps> = ({
  title,
  url,
  children,
  countrySelection,
  paymentSelection,
  amplitude,
}) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    const href = url ?? children[0].url
    window.location.href = href
    setOpen((prev) => !prev)
  }

  let activeCountry = countrySelection
  let activePayment = paymentSelection

  if (countrySelection === 'GB') {
    activeCountry = 'UK'
  }

  const isPathMatching = (elements: any) => {
    elements.map((element: any) => {
      if (element.url === window.location.pathname) setOpen(true)
      if (element.children) isPathMatching(element.children)
    })
  }

  useEffect(() => {
    if (url === window.location.pathname) {
      setOpen(true)
    }

    isPathMatching(children)
  }, [title])

  if (
    title.includes('Pay Later Offers') &&
    title !== `Pay Later Offers (${activeCountry})`
  ) {
    return null
  }

  return (
    <>
      <MultiLevelCollapse
        activeCountry={activeCountry}
        activePayment={activePayment}
        onClick={handleClick}
        url={url}
        title={title}
        isExpanded={open}
        amplitude={amplitude}
      />

      {open && <MultiLevelChild data={children} />}
    </>
  )
}

MultiLevel.displayName = 'MultiLevel'
