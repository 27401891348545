// @ts-nocheck
import { Link } from 'gatsby'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { ChevronDown, ChevronUp } from '../../../icons'
import Burger from '../../../icons/burger'
import Close from '../../../icons/close'
import LeftNavigation from '../../LeftNavigation'
import { MobileMenu } from '../MobileMenu/MobileMenu'
import './mobile-navbar.css'
import { useApplicationContext } from '../../../../provider'

interface MobileNavbarProps {
  user: any
  location: Location
  leftNav: any
}

const shyMenuDisabled = [
  '/integration-builder',
  '/video/home',
  '/video/collection',
  '/video/watch',
]

export const MobileNavbar: React.FC<MobileNavbarProps> = ({
  leftNav,
  user,
  location = {},
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isLeftNavShown, setIsLeftNavShown] = useState<boolean>(false)
  const [launcher, setLauncher] = useState<any>(null)
  const [loginURL, setLoginURL] = useState('')
  const [logoutURL, setLogoutURL] = useState('')
  const [isHomePage, setIsHomePage] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const hideShyMenu = shyMenuDisabled.includes(window.location.pathname)
  const [searchID, setSearchID] = useState('')
  const [SearchModule, setSearchModule] = useState(null)

  const { experiments } = useApplicationContext()
  const isAISearchActive =
    experiments.aiSearch === 'Trmt_Developer_AI_Search' ||
    experiments.aiSearch_acctID === 'Trmt_Developer_AI_Search_AcctID'

  const handleCloseSearch = () => {
    setIsSearchOpen(false)
  }
  const handleOpenSearch = () =>
    setIsSearchOpen((isSearchOpen) => !isSearchOpen)

  useEffect(() => {
    if (!isEmpty(user)) {
      setLoginURL(
        user.login.url.replace(
          /PAGEURL/g,
          encodeURIComponent((location && location.href) || '#'),
        ),
      )
      setLogoutURL(
        user.logout.url.replace(
          /PAGEURL/g,
          encodeURIComponent((location && location.href) || '#'),
        ),
      )
    }
    setIsHomePage(
      window.location.pathname === '/home' ||
        window.location.pathname === '/home/',
    )
  }, [location, user])

  useEffect(() => {
    setLauncher(
      typeof window !== `undefined` ? require('@paypalcorp/dx-ui-react') : null,
    )
    if (typeof window !== `undefined`) {
      setSearchModule(require('@paypalcorp/developersearchui'))
    }
  }, [])

  const handleOpenMenu = useCallback(() => {
    setIsMenuOpen((prev) => !prev)
  }, [])

  const handleShowLeftNav = () => {
    setIsLeftNavShown((prev) => !prev)
  }

  const renderLeftIcon = !isHomePage ? (
    <button
      className="mobile-navbar-left-column-button "
      onClick={handleShowLeftNav}
    >
      {isLeftNavShown ? <ChevronUp /> : <ChevronDown />}
    </button>
  ) : (
    <button
      className="mobile-navbar-left-column-button "
      onClick={handleOpenMenu}
    >
      {!isMenuOpen ? (
        <>
          <span className="sr-only">Open main menu</span>
          <Burger height="24px" width="24px" />
        </>
      ) : (
        <>
          <span className="sr-only">Close main menu</span>
          <Close style={{ marginLeft: isMenuOpen ? '-0.3rem' : '1rem' }} />
        </>
      )}
    </button>
  )

  const renderRightIcon = !isHomePage && (
    <button
      className="mobile-navbar-right-column-button "
      onClick={handleOpenMenu}
    >
      {!isMenuOpen ? (
        <>
          <span className="sr-only">Open main menu</span>
          <Burger height="24px" width="24px" />
        </>
      ) : (
        <>
          <span className="sr-only">Close main menu</span>
          <Close style={{ marginLeft: isMenuOpen ? '-0.3rem' : '1rem' }} />
        </>
      )}
    </button>
  )

  return (
    <>
      <nav className="mobile-navbar-wrapper">
        <div className="mobile-navbar-container">
          <div className="mobile-navbar-row">
            <div className="mobile-navbar-left-column">
              {!hideShyMenu && renderLeftIcon}

              <a to="/">
                <h4
                  className="mobile-navbar-left-column-title"
                  style={{ marginLeft: isMenuOpen ? '0.5rem' : '1rem' }}
                >
                  PayPal Docs
                </h4>
              </a>
            </div>

            <div className="mobile-navbar-right-column">
              <div className="mobile-navbar-right-column-search-button">
                <span className="sr-only">Open search modal</span>
                {isAISearchActive && SearchModule ? (
                  <SearchModule.Search
                    onOpen={handleOpenSearch}
                    onClose={handleCloseSearch}
                    isCodeAssist={isAISearchActive}
                  />
                ) : (
                  launcher && (
                    <launcher.SearchModal
                      isModalOpen={isSearchOpen}
                      onClose={handleCloseSearch}
                      onOpen={handleOpenSearch}
                      style={{ paddingTop: '0.5rem' }}
                    >
                      <launcher.DxUiIcon icon="Search" width="36" height="36" />
                    </launcher.SearchModal>
                  )
                )}
              </div>
              {renderRightIcon}
            </div>
          </div>
        </div>
      </nav>
      {isLeftNavShown && (
        <LeftNavigation
          showLeftNav={setIsLeftNavShown}
          leftNav={leftNav}
          location={window.location}
          hasIcons={false}
          isFullWidth
        />
      )}
      {isMenuOpen && (
        <MobileMenu user={user} loginURL={loginURL} logoutURL={logoutURL} />
      )}
    </>
  )
}
