import React from 'react'
import { Link } from 'gatsby'

export default function IsAPIDeprecated({
  apiLatestVersion,
  apiLatestVersionPath,
}) {
  if (!apiLatestVersion || !apiLatestVersionPath) {
    return null
  }
  return (
    <div className="dax-banner">
      <svg
        className="dax-banner-warning"
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 16 16"
      >
        <g fill="#F2A33A" fillRule="evenodd" transform="translate(-1 -1)">
          <polygon fill="none" points="0 18 18 18 18 0 0 0" />
          <path d="M8,10 L10,10 L10,4 L8,4 L8,10 Z M8,14 L10,14 L10,12 L8,12 L8,14 Z M9,1 C4.589,1 1,4.59 1,9 C1,13.412 4.589,17 9,17 C13.411,17 17,13.412 17,9 C17,4.59 13.411,1 9,1 L9,1 Z" />
        </g>
      </svg>
      You are viewing a deprecated version of this API. We recommend upgrading
      to{' '}
      <Link className="deprecatedBanner-link" to={apiLatestVersionPath}>
        Version {apiLatestVersion}
      </Link>
      .
    </div>
  )
}
