import React from 'react'
import { Home, ShippingPack, Qrc, Rocket, PackageSize } from '../../../icons'

const iconProps = {
  width: '24',
  height: '24',
  primaryColor: '#000000',
  secondaryColor: '#000000',
  thirdColor: '#000000',
  fourthColor: '#000000',
  fifthColor: '#000000',
  sixthColor: '#000000',
  seventhColor: '#000000',
  eighthColor: '#000000',
}

export const apiNavigation = [
  {
    href: '/home',
    title: 'Home',
    icon: <Home width="21" height="24" primaryColor={iconProps.primaryColor} />,
  },
  {
    href: '/api/rest',
    title: 'REST APIs',
    icon: <PackageSize {...iconProps} />,
  },
  {
    href: '/sdk/js/',
    title: 'JavaScript SDK',
    icon: <Qrc {...iconProps} />,
  },
  {
    href: '/sdk/mobile/',
    title: 'Mobile SDKs',
    icon: <Qrc {...iconProps} />,
  },
  {
    href: '/api/nvp-soap/',
    title: 'NVP/SOAP APIs',
    icon: <ShippingPack {...iconProps} />,
  },
  {
    href: '/braintree/graphql/',
    title: 'GraphQL API',
    icon: <Rocket {...iconProps} />,
  },
]
