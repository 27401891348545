import { Link } from 'gatsby'
import React, { ReactNode } from 'react'
import { PaymentSelection } from '../../../../../provider'
import { Arrow } from '../../../../icons'
import './multilevel-collapse.css'

interface MultiLevelCollapseProps {
  activeCountry: string
  activePayment: PaymentSelection['value']
  onClick: () => void
  url: string
  title: string
  isExpanded: boolean
  children?: ReactNode | ReactNode[]
  amplitude?: any
}

export const MultiLevelCollapse: React.FC<MultiLevelCollapseProps> = ({
  activeCountry,
  activePayment,
  onClick,
  url,
  title,
  isExpanded,
  children,
  amplitude,
}) => {
  const renderTitles = () => {
    if (title === 'Pay Later Offers (US)') {
      return 'Pay Later Offers'
    }

    return title
  }

  return (
    <>
      <li
        className={`multilevel-collapse-container multi-level-country-${
          activeCountry && activeCountry.toLowerCase()
        } multi-level-payment-${activePayment && activePayment.toLowerCase()}`}
        onClick={onClick}
      >
        <a
          href={url}
          className={
            isExpanded
              ? 'multilevel-collapse-title multilevel-collapse-open'
              : 'multilevel-collapse-title'
          }
          data-amplitude={amplitude}
        >
          {renderTitles()}
        </a>
        <div className="multilevel-arrow-icon-container" onClick={onClick}>
          <Arrow expanded={isExpanded} onClick={onClick} />
        </div>
      </li>
      {isExpanded && children}
    </>
  )
}
